import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Trip Wrapper is a PowerAutomate workflow to automatically add additional events when an event is created in the calendar.`}</p>
      <p>{`Typical use cases:`}</p>
      <ol>
        <li parentName="ol">{`When a flight is added to the calendar, add driving times to/from the airport`}</li>
        <li parentName="ol">{`When visiting a specific all-day venue, add travel time to and from the venue at specific times`}</li>
      </ol>
    </PageDescription>
    <h1>{`Getting Started`}</h1>
    <ol>
      <li parentName="ol">{`Move “TripWrapper.json.txt” to your OneDrive. If you are not using OneDrive, you will need to edit the workflow to access the file`}</li>
      <li parentName="ol">{`Import the workflow into PowerAutomate, using Import Package, and uploading “TripWrapper.zip”. During the import, you will be prompted to update the connections`}</li>
      <li parentName="ol">{`Edit the workflow and update “Get app settings file content” to point to your config file`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`If you are not using OneDrive, you can change the connection by deleting the “Get app settings file content” step and adding another method to read your config file. Be sure to rename the new step “Get app settings file content”. Ensure the “AppSettings” step is using your new step`}</li>
    </ul>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Save the workflow by clicking Save and go back to the workflow overview page`}</li>
      <li parentName="ol">{`On the workflow overview page (i.e. “Flows > Trip Wrapper”), click “Turn on” to enable the workflow`}</li>
      <li parentName="ol">{`In Outlook, create a new Event with the following properties:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Location = “Minneapolis”`}</li>
      <li parentName="ul">{`Subject = “Test Flight from MSP to MSP”`}</li>
      <li parentName="ul">{`Start = “9:55 AM”`}</li>
      <li parentName="ul">{`End = “10:35 AM”`}</li>
    </ul>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Save calendar event. Note, the location and subject must match the information in “TripWrapper.json.txt”. Start and End can be any values and the values above are just for a sample`}</li>
      <li parentName="ol">{`Go back to the workflow overview page and wait a few minutes until you see a run in the history section`}</li>
      <li parentName="ol">{`Once the run is complete, review your calendar to see the events added. You will be prompted to “Cleanup”, which will delete the events created`}</li>
      <li parentName="ol">{`You now have the basic workflow working!`}</li>
      <li parentName="ol">{`Edit “TripWrapper.json.txt” with your events`}</li>
    </ol>
    <h1>{`Options`}</h1>
    <p>{`Edit TripWrapper.json.txt to edit optional settings.`}</p>
    <h2>{`Test Mode`}</h2>
    <p>{`“test”: true - at the end of the workflow, you will be prompted to “Cleanup”. After responding to the Cleanup prompt, the wrapper events created by the workflow will be delete from the calendar. This is especially useful when you first start using the workflow and are figuring out the time offsets.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      